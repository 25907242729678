import React from "react";
import styles from "./Footer.module.scss"
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
const Footer = () =>{
  return (
    <footer className="container-fluid">
      <Row>
        <Col xs={12} lg={4} xl={5}> 
          <Row className={styles.firstColumn}>
            <Col className={`${styles.allrightsmobile} d-lg-none d-xl-block `} xs={6} lg={7} xl={7}> 
              <div className="text-uppercase" style={{marginLeft: '19px'}}>PopcultureMedia. </div > 
              <div className="text-uppercase" style={{marginLeft: '19px'}}>Conte Defee Marketing (M) Sdn Bhd. </div>
              <div className="text-uppercase" style={{marginLeft: '19px'}}> All rights reserved.</div>
            </Col>
            <Col  className={styles.contact} xs={12} lg={5} xl={4}> <a href="mailto:hello@kols.asia">contact us</a> </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4} xl={4} className={styles.socialDiv}>
          <Row className={`${styles.social} align-items-lg-center flex-xl-row` }>
            <Col xs={12} lg={4}><a href="http://instagram.com/kols.asia"  target="_blank">instagram</a></Col>
            <Col xs={12} lg={4}><a href="https://www.facebook.com/kols.asia"  target="_blank">facebook</a></Col>
            <Col xs={12} lg={4}><a href="https://www.linkedin.com/company/kolsasia"  target="_blank">linkedin</a></Col>
          </Row>
        </Col>
        <Col className={styles.design} xs={12} lg={4} xl={4}>
          <Row>
            <Col xs={12} lg={12} className={styles.designedBy}> <a href="https://synchronized.studio/"  target="_blank">Designed by Synchronized Studio</a> </Col>
            <Col className={`${styles.companyDetailsMobile} d-block d-sm-block d-md-block d-lg-none mt-3`} xs={12} lg={6}> 
              <div className="text-uppercase">PopcultureMedia. </div > 
              <div className="text-uppercase">Conte Defee Marketing (M) Sdn Bhd. </div>
              <div className="text-uppercase"> All rights reserved.</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row  className="d-none d-lg-block d-xl-none">
        <Col className={`${styles.companyDetailsMobile}`} xs={12} lg={6}> 
          <div className="text-uppercase">PopcultureMedia. </div > 
          <div className="text-uppercase">Conte Defee Marketing (M) Sdn Bhd. </div>
          <div className="text-uppercase"> All rights reserved.</div>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
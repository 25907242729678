import { FunctionComponent } from "react";
import React from "react";
import styles from "./PrivacyPolicyMobile.module.css";

const PrivacyPolicyMobile: FunctionComponent = () => {
  return (
    <html className={styles.mainPage}>
      <div className={styles.navigation}>
        <a href="./get"><img className={styles.logoIcon} alt="" src="/logo.svg" /></a>
        <a href="https://merchant.kols.asia">
          <div className={styles.button}>
              <div className={styles.content}>
                <img className={styles.icon} alt="" src="/icon7.svg" />
                <div className={styles.button1}>For Merchant</div>
                <img className={styles.icon1} alt="" src="/icon2.svg" />
              </div>
          </div>
        </a>
      </div>


      <div className={styles.scrollableContent}>
        <p className={styles.privacyPolicyHeader}>Privacy policy</p>
        <p className={styles.privacyPolicyContent}>
        KA Technologies sdn bhd Privacy Policy

Last Updated: 22 November 2024 <br/><br/>

1. Introduction<br/>
Welcome to KOLs.Asia ("App"). This Privacy Policy explains how KA Technologies sdn bhd ("we," "our," or "us") collect, use, disclose, and protect your personal information when you use the App and its services. By accessing or using the App, you agree to the terms of this Privacy Policy.<br/><br/>

2. Information We Collect<br/>
• Personal Information: We may collect information that identifies you personally, such as your name, email address, date of birth, gender, contact number, identification number, passport number, photo, data from any responses you provide to surveys and payment details.<br/>
• Usage Information: We may collect information about how you use our app, such as your interactions with the app's features and services (including records of any communication you have with us or under any chat function of the App).<br/>
We may also collect your Personal Data (as defined below) from a range of sources including but not limited to any events, activities, contests, promotional activities, surveys, social media tools or pages.<br/><br/>
3. How We Use Your Information<br/>
We will use, process, record, hold, store, share and disclose your data and information we collect and gather from you (“Personal Data”) for the following purposes:<br/>
• To subscribe or procure permissible services via the App<br/>
• To provide and improve our services<br/>
• To advertise, sell and promote products and/or services via the App<br/>
• To process, manage and maintain all user’s or merchant’s personal account under the App<br/>
• To process payments and complete transactions<br/>
• To communicate with you regarding updates and promotions<br/>
• To respond to your inquiries and provide customer support<br/>
• For any other purpose that is incidental or ancillary or in furtherance to the above purposes<br/>
Your continuance use of the App shall nevertheless amount to your consent for the processing of the Personal Data by us.<br/><br/>

4. Data Sharing<br/>
We may share your information within our group of entities and with authorised third-party service providers to, amongst others, facilitate the App’s functionality, process payments, and improve our services. We will not sell, rent, or lease your Personal Data to others.<br/><br/>

We do not collect and process any data or information regarding your banking, credit or debit card details including the payment methodology. The online payment gateway offered by us, if any, does not belong to us but authorised third party, vendor, supplier and/or service provider. We do not retain such data or information instead of the parties mentioned above or your own respective bank or financial institutions.<br/><br/>

5. Data Security<br/>
We implement security measures to protect your Personal Data from unauthorized access, disclosure, or destruction. We will place or procure various reasonable security measures to safeguard all Personal Data that has been collected or processed by us within our reasonable and commercial capacity as the internet / online or cloud-based transmission is not a 100% secured medium of communication.<br/><br/>

We do not accept responsibility or liability for any unauthorised access, loss, unlawful interceptions, hacking activities, installation or download of prohibited or unwanted software including but not limited to spyware, malware or virus which caused the loss of the Personal Data transmitted to or from the App after performance or procurement of such reasonable security procedures that are within the commercial capacity of the App.<br/><br/>

6. Your Choices<br/>
You may review and update your personal information within the app settings. You may also opt-out of marketing communications.<br/><br/>

7. Changes to this Policy<br/>
We may update this Privacy Policy from time to time. We will notify you of any material changes.<br/><br/>

8. Contact Us<br/>
If you have any questions or concerns about this Privacy Policy, please contact us at hello@kols.asia.<br/>

        </p>
 

      </div>

      <div className={styles.footer}>
        <div className={styles.lineIcon} />
        <div className={styles.footer1}>
          <div className={styles.button3}>
            <a href="./tos">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>Terms of Services</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
            <a href="https://www.kols.asia/">
              <div className={styles.button4}>
                <div className={styles.content}>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                  <div className={styles.appkolsasia}>About kols.asia</div>
                  <img className={styles.icon2} alt="" src="/icon.svg" />
                </div>
              </div>
            </a>
          </div>
          <div className={styles.copywrite2023}>
            Copywrite © 2023 KA Technologies Sdn Bhd
          </div>
        </div>
      </div>
    </html>
  );
}

export default PrivacyPolicyMobile;
